import Axios from 'axios'
import { ALL_DASHBOARD_FIAL, ALL_DASHBOARD_REQUEST, ALL_DASHBOARD_SUCCESS } from "../Constents"

export const DashboardAction = (dates) => async (dispatch) => {
    dispatch({
        type: ALL_DASHBOARD_REQUEST
    })
    try {
        const token =localStorage.getItem("token")
        let data;
        if (dates) {
            data = await Axios.get(`${process.env.REACT_APP_BASE_URL}/?${dates}&token=${token}`)
        } else {
            data = await Axios.get(`${process.env.REACT_APP_BASE_URL}/?token=${token}`)
        }

        dispatch({
            type: ALL_DASHBOARD_SUCCESS,
            payload: data.data
        })
    } catch (err) {
        if(err.message.includes("401")){
            if(window.location.pathname !== "/login"){
                window.location.pathname  = "/login"
            }
        }
        dispatch({
            type: ALL_DASHBOARD_FIAL,
            err: err.response && err.response.data.message ? err.response.data.message : err.message,
        })
    }
}