import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { DashboardReducer } from './Reducers/DashboardReducers';
import { FormDataReduccer } from './Reducers/FormDataReducer';
import {  userLoginReducer } from './Reducers/UserReducer';


const initialState = {
  
};

const reducer = combineReducers({
    AllDasboard : DashboardReducer,
    UserLogIn : userLoginReducer,
    FormData: FormDataReduccer,
  });
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
  );
  
  export default store;