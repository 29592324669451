import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { userLoginAction } from '../../Actions/UserAction'

export default function LoginScreen() {
    const dispatch = useDispatch()
    const UserLogIn = useSelector(state => state.UserLogIn)
    const { error } = UserLogIn
    const [userInfo, setuserInfo] = useState({
        username: "",
        password: ""
    })

    const LoginHandler = async () => {
        if (userInfo.name === "" || userInfo.password === "") {
            console.log("required filed is empty")
        } else {
            dispatch(userLoginAction(userInfo))
        }
    }

    const EnterLoginHandler = (e) => {
        if (e.keyCode === 13) {
            LoginHandler()
        }
    }

    return (
        <>
            <Dialog open={true} style={{ width: '100%', margin: "0 auto", backgroundColor: "#ffffff" }}>
                <DialogTitle>Log In</DialogTitle>
                <DialogContent>
                    {error === "Unauthorized!" && (
                        <small className="text-danger d-inline w-50">Invalid Username or Password</small>
                    )}
                    <TextField
                        onChange={(e) => {
                            setuserInfo({ ...userInfo, username: e.target.value })
                        }}
                        id="name"
                        autoFocus
                        style={{ marginBottom: "1rem", marginTop: "1rem" }}
                        label="Username"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        onKeyUp={(e) => EnterLoginHandler(e)}
                        onChange={(e) => {
                            setuserInfo({ ...userInfo, password: e.target.value })
                        }}
                        id="name"
                        label="Password"
                        type="password"
                        fullWidth
                        style={{ marginBottom: "1rem" }}
                        variant="standard"
                    />
                    <Button variant="contained" onClick={() => { LoginHandler() }}>
                        Log In
                    </Button>
                </DialogContent>
            </Dialog>

            {/* </div> */}
        </>
    )
}
