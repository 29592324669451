import Axios from "axios"
import { FORM_LIST_FIAL, FORM_LIST_REQUEST, FORM_LIST_SUCCESS } from "../Constents"

export const FormListAction = () => async (dispatch) => {
    dispatch({
        type: FORM_LIST_REQUEST
    })
    try {
        const token =localStorage.getItem("token")
        const data = await Axios.get(`${process.env.REACT_APP_BASE_URL}/form-data?token=${token}`)
        dispatch({
            type: FORM_LIST_SUCCESS,
            payload: data.data
        })
    } catch (err) {
        if (err.message.includes("401")) {
            if (window.location.pathname !== "/login") {
                window.location.pathname = "/login"
            }
        }
        dispatch({
            type: FORM_LIST_FIAL,
            payload: err.response && err.response.data.message ? err.response.data.message : err.message,
        })
    }
}