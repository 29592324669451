import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import ResponsiveDrawer from './Layout/NewDrower';
import PATH from './Paths';
import FormData from './Screens/FormData/FormData';
import HomeScreen from './Screens/HomeScreen/HomeScreen';
import LoginScreen from './Screens/LoginScreen/LoginScreen';


function App() {
  const Path = new PATH();
  const [windowR, setwindowR] = useState(0)
  const getWin = () => {
    if (typeof window !== "undefined") {
      setwindowR(window)
    }
  }
  useEffect(() => {
    getWin()
  }, [])



  const Routs = (
    <main>
      <Routes>
        <Route path={Path.dashboard} element={<HomeScreen />} />
        <Route path={Path.logIn.login} element={<LoginScreen />} />
        <Route path={Path.forms} element={<FormData />} />
      </Routes>
    </main>
  )


  return (
    <BrowserRouter>
      <div className="App">
        <ResponsiveDrawer Routs={Routs} windows={windowR} />
      </div>
    </BrowserRouter >
  );
}

export default App;
