import * as React from 'react';

export default function ErrorBox(props) {
  const { setErrorOpen, errorMsg } = props;



  React.useEffect(() => {
    if(errorMsg.includes("Unauthenticated")){
      localStorage.removeItem("token")
      window.location.pathname = "/login"
    }

  }, [errorMsg])


  const ErrorHandler = () => {
    setErrorOpen(false);
    if (errorMsg.includes("Unauthenticated.")) {
      localStorage.removeItem("token")
      window.location.pathname = "/login"
    }
    localStorage.removeItem("errors")
  }

  return (
    <div
      type="button"
      onClick={() => ErrorHandler()}
      style={{
        zIndex: "10000000",
        display: "flex",
        position: "fixed",
        top: "0", left: "0",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0000004d"
      }}>
      <div style={{
        backgroundColor: "#ffffff",
        borderRadius: "5px",
        boxShadow: "0px 0px 30px #00000091",
        padding: "1rem 1rem 2rem 1rem"
      }}>
        <div className="text-danger">
          {errorMsg}
        </div>

      </div>
    </div>
  );
}

