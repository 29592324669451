import { ALL_DASHBOARD_FIAL, ALL_DASHBOARD_REQUEST, ALL_DASHBOARD_SUCCESS } from "../Constents";

export const DashboardReducer = (state = { dashboardData: {} }, action) => {
    switch (action.type) {
        case ALL_DASHBOARD_REQUEST:
            return { loading: true }
        case ALL_DASHBOARD_SUCCESS:
            return { loading: false, dashboardData: action.payload }
        case ALL_DASHBOARD_FIAL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}