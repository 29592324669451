import { FORM_LIST_FIAL, FORM_LIST_REQUEST, FORM_LIST_SUCCESS } from "../Constents";

export const FormDataReduccer = (state = { forms: [] }, action) => {
    switch (action.type) {
        case FORM_LIST_REQUEST:
            return { loading: true }
        case FORM_LIST_SUCCESS:
            return { loading: false, forms: action.payload }
        case FORM_LIST_FIAL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
} 