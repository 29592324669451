import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import PATH from '../Paths';
import { Dashboard } from '@material-ui/icons';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';


const drawerWidth = 240;
function ResponsiveDrawer(props) {
    const { windows } = props;
    const Navigation = useNavigate();
    const location = window.location
    const pageSelected = window.location.pathname
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const Path = new PATH()
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    const LogoutHandler = () => {
        localStorage.removeItem("token")
        Navigation("/login")
    }



    const drawer = (
        <div>
            <Toolbar />
            <List>
                <Link to={Path.dashboard}>
                    <ListItem button selected={pageSelected === Path.dashboard} >
                        <ListItemIcon><Dashboard /></ListItemIcon>
                        <ListItemText primary={"Dashboard"} />
                    </ListItem>
                </Link>
            </List>
            <List>
                <Link to={Path.forms}>
                    <ListItem button selected={pageSelected === Path.forms} >
                        <ListItemIcon><FormatIndentDecreaseIcon /></ListItemIcon>
                        <ListItemText primary={"Form Data"} />
                    </ListItem>
                </Link>
            </List>
        </div >
    )
    const container = windows !== 0 ? windows.document.body : undefined


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                style={{ width: "100%", zIndex: "10000" }}
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" style={{ width: "100%", textAlign: "start" }}>
                        MAX-MAN
                    </Typography>
                    {
                        location.pathname !== "/login" && (
                            <IconButton onClick={(e) => LogoutHandler(e)} style={{ color: "#ffffff", backgroundColor: "transparent", borderRadius: "2px" }}>
                                <LogoutIcon />
                                <span style={{ fontSize: "1rem", padding: "0.5rem" }}>
                                    Log Out
                                </span>
                            </IconButton>
                        )
                    }

                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {props.Routs}
            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
