import { USER_LOGIN_FIAL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS } from "../Constents";

export const userLoginReducer = (state = { LoginDetails: {}, loading: true }, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }
        case USER_LOGIN_SUCCESS:
            return { success: true, loading: false, LoginDetails: action.payload }
        case USER_LOGIN_FIAL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


