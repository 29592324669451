import Axios from "axios"
import { USER_LOGIN_FIAL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS } from "../Constents"

export const userLoginAction = (data) => async (dispatch) => {
    dispatch({ type: USER_LOGIN_REQUEST })
    try {
        const result = await Axios.post(`${process.env.REACT_APP_BASE_URL}/login`, data)
        localStorage.setItem("token", result.data[0].token)
        window.location.pathname = "/"
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: result.data
        })
    } catch (err) {
        dispatch({
            type: USER_LOGIN_FIAL,
            payload: err.response && err.response.data.message ? err.response.data.message : err.message,
        })
    }
}
