import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormListAction } from '../../Actions/FormAction'
import ErrorBox from '../../Components/ErroeBox'
import LoadingBox from '../../Components/LoadingBox'

export default function FormData() {
    const dispatch = useDispatch()
    const Forms = useSelector(state => state.FormData)
    const { error, loading, forms } = Forms
    const [ErrorOpen, setErrorOpen] = useState(false)

    useEffect(() => {
        dispatch(FormListAction())
        if(error){
            setErrorOpen(true)
        }
        // eslint-disable-next-line 
    }, [dispatch]);

    const columns = [
        { field: "id", headerName: "ID", width:100 },
        { field: "name", headerName: "Customer Name", flex: 1 },
        { field: "number", headerName: "Customer Phone", flex: 1 },
        { field: "address", headerName: "Address", flex: 1 },
        { field: "post", headerName: "Blog", flex: 1 },
        { field: "product", headerName: "Product", flex: 1 },
        { field: "language", headerName: "Language", flex: 1 },
        { field: "created_at", headerName: "Created_At", flex: 1 },
        { field: "updated_at", headerName: "Updated_At", flex: 1 },
    ]

    return (
        <>
            {loading && <LoadingBox />}
            {
                !loading && ErrorOpen && !error.includes("401") && <ErrorBox ErrorOpen={ErrorOpen} setErrorOpen={setErrorOpen} errorMsg={error} />
            }
            <div className="row  pb-4">
                <div className="col-12">
                    <h2 className="text-start text-dark">
                        Form Data
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <DataGrid autoHeight={true} rows={forms ? forms.length > 0 &&  forms : []} getRowId={e => e.id} columns={columns} pageSize={10} />
                </div>
            </div>
        </>
    )
}
