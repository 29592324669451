export const ALL_DASHBOARD_REQUEST = "ALL_DASHBOARD_REQUEST"
export const ALL_DASHBOARD_SUCCESS = "ALL_DASHBOARD_SUCCESS"
export const ALL_DASHBOARD_FIAL = "ALL_DASHBOARD_FIAL"

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FIAL = "USER_LOGIN_FIAL"

export const FORM_LIST_REQUEST = "FORM_LIST_REQUEST"
export const FORM_LIST_SUCCESS = "FORM_LIST_SUCCESS"
export const FORM_LIST_FIAL = "FORM_LIST_FIAL"