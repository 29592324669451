import { TextField } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardAction } from '../../Actions/DashoboardAction'
import LoadingBox from '../../Components/LoadingBox'
import ErrorBox from "../../Components/ErroeBox"


export default function HomeScreen() {
    const dispatch = useDispatch()
    const AllDasboard = useSelector(state => state.AllDasboard)
    // eslint-disable-next-line

    const { loading, dashboardData } = AllDasboard
    const [date, setdate] = useState({
        start: "",
        end: ""
    })
    const [errorMsg, seterrorMsg] = useState("")
    const [ErrorOpen, setErrorOpen] = useState(false)
    const ResponseError = localStorage.getItem("errors") !== null && localStorage.getItem("errors")
    useEffect(() => {
        if (ResponseError) {
            console.log(ResponseError)
            seterrorMsg(ResponseError)
            setErrorOpen(true)
        }
    }, [ResponseError])


    const rowsDefault = dashboardData && dashboardData.today && dashboardData.today.data
    const rowsfiltered = dashboardData && dashboardData.filtered && dashboardData.filtered.data

    const columnsFiltered = [
        { field: "blog_name", headerName: "Form Name", flex: 1 },
        { field: "leads", headerName: "Leads", flex: 1 },
    ]

    let dates;

    const requestStartDateHandler = (e) => {
        date.start = `from=${e.target.value}`
        setdate(date)
        dates = date.start + date.end
        if (date.end.length > 0) {
            dispatch(DashboardAction(dates))
        }
    }
    const requestEndDateHandler = (e) => {
        date.end = `&to=${e.target.value}`
        setdate(date)
        dates = date.start + date.end
        if (date.start.length > 0) {
            dispatch(DashboardAction(dates))
        }
    }


    useEffect(() => {
        dispatch(DashboardAction())
    }, [dispatch])


    const columnsDefault = [
        {
            field: "blog_name", headerName: "Form Name", flex: 1
        },
        { field: "leads", headerName: "Leads", flex: 1 },
    ]

    document.title = "Dashboard"

    return (
        <>
            {
                loading && <LoadingBox></LoadingBox>
            }
            {
                !loading && ErrorOpen && !errorMsg.includes("401") && <ErrorBox ErrorOpen={ErrorOpen} setErrorOpen={setErrorOpen} errorMsg={errorMsg} />
            }
            <div className="row  pb-4">
                <div className="col-12">
                    <h2 className="text-start text-dark">
                        Dashboard
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-lg-6 col-md-6 pb-5">
                    <div className="row m-0">
                        <div className="col-12 mb-3">
                            <div className="border pt-4 p-2">
                                <h5 className="text-start">
                                    Today Leads
                                </h5>
                                <h3 className="text-start">
                                    {dashboardData && dashboardData.today && dashboardData.today.total}
                                </h3>
                            </div>
                        </div>
                        <div className="col-12">
                            <DataGrid autoHeight={true} rows={rowsDefault ? rowsDefault : []} getRowId={e => e.blog_name} columns={columnsDefault} pageSize={10} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6">
                    <div className="row m-0">
                        <div className="col-lg-12 mb-3">
                            <div className="border pt-4 p-2 ">
                                <div className="d-flex gap-x flex-wrap gap-y DashboardDate
                                justify-content-between
                                align-item-sm-center align-item-md-center">
                                    <TextField
                                        id="date"
                                        label="Start Date"
                                        onChange={(e) => requestStartDateHandler(e)}
                                        type="date"
                                        // sx={{ width: 220 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        id="date"
                                        label="End Date"
                                        type="date"
                                        onChange={(e) => requestEndDateHandler(e)}
                                        // sx={{ width: 220 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <div>
                                        <h5 className='w-100 test-end'>
                                            Totale Leads
                                        </h5>
                                        <h3>
                                            {dashboardData && dashboardData.filtered && dashboardData.filtered.total}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <DataGrid autoHeight={true} rows={rowsfiltered ? rowsfiltered : []} getRowId={e => e.blog_name} columns={columnsFiltered ? columnsFiltered : []} pageSize={10} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
